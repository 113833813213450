<template>
    <div id="page-sip-trunk-bulk-edit-numbers" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to SIP Trunks</router-link>

                    <h1 class="heading">Forward Phone Numbers</h1>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <div class="phone-number" v-for="phone_number in phone_numbers" :key="phone_number.UUID">
                            <h3 class="heading">{{ phone_number.PhoneNumber }}</h3>

                            <div class="form-group">
                                <div class="form-controls">
                                    <app-dropdown-select
                                        v-model="phone_number.CFEnabled"
                                        :options="forwarding_options"

                                        key-value="value"
                                        key-title="title"

                                        label="Always forwarding"
                                    />

                                    <app-input
                                        v-model="phone_number.CFDestination"

                                        label="Destination"

                                        :error="errors[phone_number.UUID] && errors[phone_number.UUID].CFDestination"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <app-error v-model="errors.save"></app-error>

                    <button class="btn btn-primary btn-save" :disabled="loading || !is_changed" @click="save">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'

const FORWARDING_OPTIONS = [
    {
        value: true,
        title: 'Enabled',
    },
    {
        value: false,
        title: 'Disabled',
    },
]

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            original_phone_numbers: [],
            phone_numbers: [],

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {},
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.sip_trunk_uuid) {
                this.getPhoneNumbers()
            }
        },

        getPhoneNumbers() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                SipTrunkUUID: this.sip_trunk_uuid,
            }

            this.$store.dispatch('api_siptrunk/GetSipTrunkNumbersBySipTrunkUUID', params)
                .then(({ SipTrunkNumbers }) => {
                    this.original_phone_numbers = JSON.parse(JSON.stringify(SipTrunkNumbers))
                    this.phone_numbers          = JSON.parse(JSON.stringify(SipTrunkNumbers))

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        save() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                SipTrunkNumbers: this.phone_numbers.map(phone_number => ({
                    UUID: phone_number.UUID,
                    SPID: phone_number.SPID,
                    CFDestination: phone_number.CFDestination,
                    CFEnabled: phone_number.CFEnabled,
                }))
            }

            this.$store.dispatch('api_siptrunk/BulkEditSipTrunkNumber', params)
                .then(({ SipTrunkNumbers }) => {
                    this.errors = {}
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                        this.$emit('saved')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }
                })
                .catch(error => {
                    this.errors.save = errMessage(error)
                    this.loading = false
                })
        },

        reset() {
            this.errors = {}

            this.original_phone_numbers = []
            this.phone_numbers          = []
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        forwarding_options() {
            return FORWARDING_OPTIONS
        },

        back_to() {
            return this.referrer && this.referrer.name == 'sip-trunking-sip-trunks'
                ? this.referrer
                : { name: 'sip-trunking-sip-trunks' }
        },

        sip_trunk_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        is_changed() {
            let is_changed = false

            for (let i = 0, len = this.phone_numbers.length; i < len; i++) {
                if (
                    this.phone_numbers[i].CFEnabled != this.original_phone_numbers[i].CFEnabled
                    || this.phone_numbers[i].CFDestination != this.original_phone_numbers[i].CFDestination
                ) {
                    is_changed = true
                    break
                }
            }

            return is_changed
        },
    },

    watch: {
        uuid() {
            if (this.sip_trunk_uuid) {
                this.getPhoneNumbers()
            } else {
                this.reset()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-sip-trunk-bulk-edit-numbers {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .phone-number {
            margin-bottom: 40px;

            h3 {
                &.heading {
                    margin-bottom: 32px;
                }
            }

            .form-group {
                .form-controls {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .app-dropdown-select {
                        max-width: calc(40% - 15px);
                    }

                    .app-input {
                        max-width: calc(60% - 15px);
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-sip-trunk-bulk-edit-numbers {
        .board {
            padding: 16px 8px;

            .phone-number {
                margin-bottom: 32px;

                h3 {
                    &.heading {
                        margin-bottom: 24px;
                    }
                }

                .form-group {
                    .form-controls {
                        .app-dropdown-select {
                            max-width: 100%;

                            margin-bottom: 24px;
                        }

                        .app-input {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}
</style>