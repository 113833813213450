<template>
    <div id="page-sip-trunk-product-notes" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to SIP Trunks</router-link>

                    <h1 class="heading">SIP Trunk Notes</h1>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <h3 class="heading" v-if="on_sidebar">SIP Trunk Notes</h3>

                        <div class="form-group">
                            <div class="form-controls">
                                <app-textarea
                                    v-model="note"

                                    label="Write a note..."

                                    :required="true"
                                    :error="errors.note"
                                />

                                <app-error v-model="errors.save"></app-error>

                                <button class="btn btn-primary btn-post" :disabled="loading" @click="post">Submit</button>
                            </div>
                        </div>

                        <!--
                            <div class="search-container">
                                <div class="magnifier-container">
                                    <div class="title">Comments</div>
                                    <i></i>
                                </div>

                                <input
                                    v-model="value"

                                    type="text"
                                    placeholder="Search"

                                    ref="input"

                                    @input="onInput"
                                    @keyup.enter="onEnter"
                                >

                                <div class="sorting">
                                    <button class="sorting-direction" :class="{ desc }" @click="toggleSortingDirection"><i></i></button>
                                </div>
                            </div>
                        -->

                        <div class="notes" v-if="has_at_least_one_note">
                            <div class="note" v-for="note in product_notes_list" :key="note.UUID">
                                <div class="details">
                                    <div class="author">{{ note.author }}</div>
                                    <div class="date">{{ note.date }}</div>
                                </div>
                                <div class="message">{{ note.message }}</div>
                            </div>
                        </div>

                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTextarea from '@/components/app-textarea'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

import SipTrunkValidator from '@/validators/sip-trunk-validator'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appTextarea,
        appPagination,
    },

    data() {
        return {
            note: null,

            filter: {
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc',
                },
            },

            value: null,
            delay: 250, // The number of milliseconds to wait
            debounce: null,

            product_notes: [],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {},
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        focus() {
            /**
             * @TODO
             */
            this.$refs.input.focus()
        },

        onInput() {
            if (this.delay) {
                clearTimeout(this.debounce)

                this.debounce = setTimeout(() => {
                    this.search()
                }, this.delay)
            } else {
                this.search()
            }
        },

        onEnter() {
            if (this.delay) {
                clearTimeout(this.debounce)
            }

            this.search()
        },

        search() {
            console.log('search()')
            /**
             * @TODO
             */
        },

        init() {
            if (this.sip_trunk_uuid) {
                this.getProductNotes()
            }
        },

        getProductNotes(page) {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                ProductInstanceUUID: this.sip_trunk_uuid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_product_instance/FindProductInstanceNotesPaginated', params)
                .then(({ ProductInstanceNotes, PageInfo }) => {
                    this.product_notes = ProductInstanceNotes

                    this.pagination.page = PageInfo.PageNumber
                    this.pagination.total = PageInfo.TotalItemCount

                    this.errors = {}

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        validation() {
            this.errors = {}

            const rule = 'Note'
            let is_valid = true

            const error = SipTrunkValidator.isInvalid(rule, this.note)

            if (error) {
                is_valid = false
                this.errors.note = error
            }

            return is_valid
        },

        post() {
            if (this.validation()) {
                this.loading = true
    
                const params = {
                    SPID: this.current_spid,
                    ProductInstanceUUID: this.sip_trunk_uuid,
                    Text: this.note,
                }

                console.log('params', params)

                this.$store.dispatch('api_product_instance/AddProductInstanceNote', params)
                    .then(() => {
                        this.note = null

                        this.getProductNotes()
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)
                        this.loading = false
                    })
            }
        },

        reset() {
            this.note = null

            this.filter.sort.field = 'CreatedAtNanos'
            this.filter.sort.order = 'desc'

            this.pagination.page = 1
            this.pagination.total = 0

            this.errors = {}
        },

        onPageChange(page) {
            this.getProductNotes(page)
        },

        toggleSortingDirection() {
            /**
             * @TODO
             */
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'sip-trunking-sip-trunks'
                ? this.referrer
                : { name: 'sip-trunking-sip-trunks' }
        },

        sip_trunk_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        desc() {
            /**
             * @TODO
             */
            return false
        },

        product_notes_list() {
            return this.product_notes.map(note => ({
                UUID: note.UUID,
                author: note.CreatedBy,
                date: formatDateNano(note.CreatedAtNanos, 'YYYY-MM-DD HH:mm:ss'),
                message: note.Text,
            }))
        },

        has_at_least_one_note() {
            return this.product_notes.length > 0
        },
    },

    watch: {
        uuid() {
            this.reset()

            if (this.sip_trunk_uuid) {
                this.getProductNotes()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-sip-trunk-product-notes {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin: 0 0 24px 0;
    }

    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h3 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        .form-group {
            margin-bottom: 32px;

            .form-controls {
                display: flex;
                flex-direction: column;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .app-textarea {
            height: 144px;

            margin-bottom: 24px;
        }

        .search-container {
            display: flex;

            width: 100%;
            height: 48px;

            background-color: var(--color-sidebar-search-bg);
            border-radius: $border-radius-primary;

            .magnifier-container {
                display: flex;
                align-items: center;

                padding: 0 8px 0 24px;

                .title {
                    margin-right: 8px;

                    font-size: 16px;
                    line-height: 24px;

                    color: var(--color-comments-primary);

                    text-transform: uppercase;
                }

                i {
                    display: inline-block;

                    width: 24px;
                    height: 24px;

                    color: var(--color-comments-primary);

                    @include icon-before($icon-search);
                }
            }

            input {
                width: 100%;

                font-size: 16px;
                line-height: 24px;

                color: var(--color-text-primary);

                &::-webkit-input-placeholder {
                    color: var(--color-comments-secondary);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &::-moz-placeholder {
                    color: var(--color-comments-secondary);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &:-ms-input-placeholder {
                    color: var(--color-comments-secondary);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &:-moz-placeholder {
                    color: var(--color-comments-secondary);
                    opacity: 1;
                    transition: opacity $transition-duration-primary;
                }

                &:focus {
                    &::-webkit-input-placeholder {
                        opacity: 0;
                    }

                    &::-moz-placeholder {
                        opacity: 0;
                    }

                    &:-ms-input-placeholder {
                        opacity: 0;
                    }

                    &:-moz-placeholder {
                        opacity: 0;
                    }
                }
            }

            .sorting {
                display: flex;
                align-items: center;
                padding: 0 24px 0 8px;

                .sorting-direction {
                    width: 24px;
                    height: 24px;

                    i {
                        display: inline-block;

                        width: 24px;
                        height: 24px;

                        color: var(--color-comments-primary);

                        transition: $transition-duration-primary;

                        @include icon-before($icon-arrow-bottom);
                    }

                    &.desc {
                        transform: rotateX(180deg);
                    }

                    &:hover {
                        i {
                            color: var(--color-table-action-primary);
                        }
                    }
                }
            }
        }

        .notes {
            .note {
                padding: 16px 24px;

                font-size: 16px;
                line-height: 24px;

                color: var(--color-comments-primary);

                border-bottom: 1px solid var(--color-divider);

                .details {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;

                    .author {
                        font-weight: bold;
                    }

                    .date {
                        font-size: 14px;

                        color: var(--color-comments-secondary);
                    }
                }
            }
        }

        .app-pagination {
            margin-top: 24px;
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .board {
            .form-group {
                .form-controls {
                    align-items: center;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-sip-trunk-product-notes {
        .board {
            padding: 8px;

            .form-group {
                margin-bottom: 24px;

                .form-controls {
                    align-items: center;
                }
            }

            .app-textarea {
                height: 129px;
            }

            .notes {
                .note {
                    padding: 8px 0;

                    font-size: 14px;
                }
            }

            .app-pagination {
                margin-top: 16px;
            }
        }
    }
}
</style>