<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <bulk-edit-numbers-details v-if="sidebar.bulk_edit_sip_trunk_numbers"
                    :uuid="sip_trunk_uuid"

                    ref="bulk-edit-numbers-details"

                    @close-sidebar="closeSidebar"
                    @saved="getSIPTrunks"
                />

                <product-notes-details v-else-if="sidebar.product_notes"
                    :uuid="sip_trunk_uuid"

                    ref="product-notes-details"

                    @close-sidebar="closeSidebar"
                />

                <add-phone-number-details v-else-if="sidebar.add_phone_number"
                    :uuid="sip_trunk_uuid"

                    ref="add-phone-number-details"

                    @close-sidebar="closeSidebar"
                    @saved="getSIPTrunks"
                />

                <sip-trunk-details v-else
                    :uuid="sip_trunk_uuid"

                    ref="sip-trunk-details"

                    @close-sidebar="closeSidebar"
                    @saved="getSIPTrunks"
                />
            </app-sidebar-details>
        </template>

        <div class="page-sip-trunking-sip-trunks container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">SIP Trunks</h1>
                </div>

                <div class="col-12">
                    <app-error v-model="errors.show" :message="errors.message"></app-error>

                    <app-search-box
                        v-model="filter.word"

                        label="Search"
                        icon="search"

                        :debounce="1000"

                        :show-clear-button="true"

                        @search="onSearch"
                    />

                    <app-table
                        class="sip-trunks-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"

                        :loading="loading"

                        :selected-row="sip_trunk_uuid"

                        @toggle-product-notes-sidebar="onToggleProductNotesSidebar"
                        @add-phone-number="onAddPhoneNumber"
                        @bulk-edit-sip-trunk-numbers="onBulkEditSipTrunkNumbers"
                        @edit-sip-trunk="onEditSIPTrunk"
                        @remove-sip-trunk="onRemoveSIPTrunk"
                    />

                    <div class="actions">
                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />

                        <!--
                            <button class="btn btn-primary btn-add-sip-trunk"
                                :disabled="loading"

                                @click="addNewSIPTrunk"
                            >Add SIP Trunk</button>
                        -->
                    </div>
                </div>
            </div>

            <app-dialog-sip-trunk-removal
                v-if="dialogs.sip_trunk.remove.show"
                v-model="dialogs.sip_trunk.remove.show"

                :uuid="dialogs.sip_trunk.remove.uuid"

                @confirm="onDialogSIPTrunkRemovalConfirm"
                @close="onDialogSIPTrunkRemovalClose"
            />
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import sipTrunkDetails from '@/views/sip-trunk'
import bulkEditNumbersDetails from '@/views/sip-trunk/bulk-edit-numbers'
import productNotesDetails from '@/views/sip-trunk/product-notes'
import addPhoneNumberDetails from '@/views/sip-trunk/add-phone-number'

import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appSearchBox from '@/components/app-search-box'

import appDialogSipTrunkRemoval from './components/app-dialog-sip-trunk-removal'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        sipTrunkDetails,
        bulkEditNumbersDetails,
        productNotesDetails,
        addPhoneNumberDetails,

        appError,
        appTable,
        appPagination,
        appSearchBox,

        appDialogSipTrunkRemoval,
    },

    data() {
        return {
            filter: {
                word: '',

                sort: {
                    field: 'Username',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Username',    title: 'Username', },
                { field: 'Enabled',     title: 'Enabled',  },
                { field: 'PilotNumber', title: 'Pilot',    },
            ],

            cols: [
                { key: 'Username',    title: 'Username',    sort: { field: 'Username'    }, highlight: true, },
                { key: 'Enabled',     title: 'Enabled',     sort: { field: 'Enabled'     },                  },
                { key: 'PilotNumber', title: 'Pilot',       sort: { field: 'PilotNumber' },                  },
                { key: 'Description', title: 'Description',                                                  },

                { key: 'Forwarded', type: 'indicator', icon: 'forward', tooltip: 'Number forwarded', behavior: 'detached' },

                { actions: [
                    { action: 'toggle-product-notes-sidebar', title: 'Add comment',            },
                    { action: 'add-phone-number',             title: 'Add Phone Number',       },
                    { action: 'bulk-edit-sip-trunk-numbers',  title: 'Forward Phone Numbers',  },
                    { action: 'edit-sip-trunk',               title: 'Edit SIP Trunk details', },
                ], behavior: 'detached', },

                { key: 'remove',        action: 'remove-sip-trunk',             icon: 'remove',        behavior: 'detached', },
            ],
            sip_trunks: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
              sip_trunk: {
                remove: {
                  show: false,
                  uuid: null,
                },
              },
            },

            sidebar: {
                create: false,
                edit:   false,
                bulk_edit_sip_trunk_numbers: false,
                product_notes: false,
                add_phone_number: false,
            },

            sip_trunk_uuid: null,

            is_mobile_mode: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getSIPTrunks()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getSIPTrunks()
        },

        onPageChange(page) {
            this.getSIPTrunks(page)
        },

        onSearch() {
            this.getSIPTrunks(1)
        },

        getSIPTrunks(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SPID':                     this.current_spid,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            if (this.filter.word) {
                params.SmartSearch = this.filter.word
            }

            this.$store.dispatch('api_siptrunk/GetSIPTrunksPaginated', params)
                .then(response => {
                    if (!response.SIPTrunks || !response.PageInfo) {
                        return Promise.reject(response)
                        console.log('problems');
                    }

                    this.sip_trunks = response.SIPTrunks

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.sip_trunks = []

                    this.loading = false
                })
        },

        onToggleProductNotesSidebar(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'sip-trunking-sip-trunk-product-notes', params: { uuid: row.uuid } })
            } else {
                this.sidebar.create = false
                this.sidebar.edit   = false
                this.sidebar.bulk_edit_sip_trunk_numbers = false

                if (this.sidebar.product_notes && (this.sip_trunk_uuid == row.uuid)) {
                    this.sip_trunk_uuid = null

                    this.closeSidebar()
                } else {
                    this.sidebar.product_notes = true

                    this.sip_trunk_uuid = row.uuid

                    this.openSidebar()
                }
            }
        },

        onAddPhoneNumber(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'sip-trunking-sip-trunk-add-phone-number', params: { uuid: row.uuid } })
            } else {
                this.sidebar.create = false
                this.sidebar.edit   = false
                this.sidebar.bulk_edit_sip_trunk_numbers = false
                this.sidebar.product_notes = false
                this.sidebar.add_phone_number = true

                this.sip_trunk_uuid = row.uuid

                this.openSidebar()
            }
        },

        onBulkEditSipTrunkNumbers(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'sip-trunking-sip-trunk-bulk-edit-numbers', params: { uuid: row.uuid } })
            } else {
                this.sidebar.create = false
                this.sidebar.edit   = false
                this.sidebar.bulk_edit_sip_trunk_numbers = true
                this.sidebar.product_notes = false
                this.sidebar.add_phone_number = false

                this.sip_trunk_uuid = row.uuid

                this.openSidebar()
            }
        },

        onEditSIPTrunk(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'sip-trunking-sip-trunk-edit', params: { uuid: row.uuid } })
            } else {
                this.sidebar.create = false
                this.sidebar.edit   = true
                this.sidebar.bulk_edit_sip_trunk_numbers = false
                this.sidebar.product_notes = false
                this.sidebar.add_phone_number = false

                this.sip_trunk_uuid = row.uuid

                this.openSidebar()
            }
        },

        onRemoveSIPTrunk(row) {
            this.dialogs.sip_trunk.remove.uuid = row.uuid
            this.dialogs.sip_trunk.remove.show = true
        },

        addNewSIPTrunk() {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'sip-trunking-sip-trunk-create' })
            } else {
                this.sidebar.create = true
                this.sidebar.edit   = false
                this.sidebar.bulk_edit_sip_trunk_numbers = false
                this.sidebar.product_notes = false
                this.sidebar.add_phone_number = false

                this.sip_trunk_uuid = null

                this.openSidebar()
            }
        },

        onDialogSIPTrunkRemovalConfirm() {
            if (this.sip_trunk_uuid == this.dialogs.sip_trunk.remove.uuid) {
                this.closeSidebar()
            }

            this.getSIPTrunks()

            this.dialogs.sip_trunk.remove.uuid = null
            this.dialogs.sip_trunk.remove.show = false
        },

        onDialogSIPTrunkRemovalClose() {
            this.dialogs.sip_trunk.remove.uuid = null
            this.dialogs.sip_trunk.remove.show = false
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.sip_trunk_uuid = null

            this.$emit('close-sidebar')

            if (this.sidebar.bulk_edit_sip_trunk_numbers) {
                this.$refs['bulk-edit-numbers-details'].reset()
            } else if (this.sidebar.product_notes) {
                this.$refs['product-notes-details'].reset()
            } else if (this.sidebar.add_phone_number) {
                this.$refs['add-phone-number-details'].reset()
            } else {
                this.$refs['sip-trunk-details'].reset()
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            '$mobile_size',
        ]),

        rows() {
            const rows = []

            for (let i = 0, len = this.sip_trunks.length; i < len; i++) {
                const sip_trunk = this.sip_trunks[i]

                rows.push({
                    uuid: sip_trunk.UUID,
                    Username: sip_trunk.Username ? sip_trunk.Username : '?' ,
                    Enabled: sip_trunk.Enabled ? 'True' : 'False',
                    PilotNumber: sip_trunk.PilotNumber ? sip_trunk.PilotNumber : '?' ,
                    Description: sip_trunk.Description ? sip_trunk.Description : '?' ,
                    Trunking: sip_trunk.Trunking ? 'True' : 'False',
                    MenuActive: sip_trunk.MenuActive ? 'True' : 'False',

                    Forwarded: {
                        show: sip_trunk.HasActiveForwardsIndicator,
                    },
                })
            }

            return rows
        },

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.create: {
                    title = 'Add new SIP Trunk'
                } break

                case this.sidebar.edit: {
                    title = 'Edit SIP Trunk details'
                } break

                case this.sidebar.bulk_edit_sip_trunk_numbers: {
                    title = 'Forward Phone Numbers'
                } break

                case this.sidebar.product_notes: {
                    title = 'SIP Trunk Notes'
                } break

                case this.sidebar.add_phone_number: {
                    title = 'Add Phone Number'
                } break
            }

            return title
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-sip-trunking-sip-trunks {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .app-search-box {
        margin-bottom: 30px;
    }

    .sip-trunks-table {
        @include table-cols-width((150px, 100px, 150px, 250px, 48px, 48px, 48px), true, 3);
    }

    & > .row {
        & > .col-12 {
            & > .actions {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .app-pagination,
                .btn-add-sip-trunk {
                    margin-top: 30px;
                }

                .app-pagination {
                    flex-grow: 1;

                    margin-right: 16px;
                }
            }
        }
    }

    .app-dialog {
        z-index: $z-index-app-navigation-bar + 1;
    }
}

@media (max-width: $tablet-size) {
    .page-sip-trunking-sip-trunks {
        .sip-trunks-table {
            @include table-cols-width((150px, 80px, 150px, 250px, 48px, 48px, 48px), true, 3);
        }

        & > .row {
            & > .col-12 {
                & > .actions {
                    flex-direction: column;

                    .app-pagination {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-sip-trunking-sip-trunks {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .app-search-box {
            margin-bottom: 16px;
        }

        .sip-trunks-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        & > .row {
            & > .col-12 {
                & > .actions {
                    .app-pagination,
                    .btn-add-sip-trunk {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
</style>