<template>
    <div id="page-sip-trunk-add-phone-number" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to SIP Trunks</router-link>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <h2 class="heading">New Individual Number Form</h2>

                        <div class="form-container">
                            <div class="form-section">
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-radio-buttons
                                            v-model="number.from"
                                            :items="number.options"

                                            label="Select New Number from"
                                            :inline="true"
                                        />
                                    </div>
                                    <div class="form-controls allocation" v-if="is_allocation">
                                        <app-autocomplete
                                            label="Number"

                                            ref="phone-numbers-autocomplete"

                                            :options="filtered_phone_numbers"
                                            :delay="500"
                                            :error="errors.Number"
                                            :disabled="loading"

                                            @typing="filterPhoneNumbers"
                                            @select="selectNumber"
                                        />
                                    </div>
                                    <div class="form-controls port-in-number" v-else>
                                        <app-input
                                            v-model="number_form.NumberRange"

                                            label="Number/NumberRange"

                                            :error="errors.NumberRange"
                                            :disabled="loading"

                                            @change="errors.NumberRange = null"
                                        />

                                        <app-input
                                            v-model="number_form.LSP"

                                            label="LSP"

                                            :error="errors.LSP"
                                            :disabled="loading"

                                            @change="errors.LSP = null"
                                        />

                                        <app-input
                                            class="LSPAccountID"

                                            v-model="number_form.LSPAccountID"

                                            label="LSP Account #"

                                            :error="errors.LSPAccountID"
                                            :disabled="loading"

                                            @change="errors.LSPAccountID = null"
                                        />

                                        <app-input
                                            class="LSPAccountName"

                                            v-model="number_form.LSPAccountName"

                                            label="LSP Account Name"

                                            :error="errors.LSPAccountName"
                                            :disabled="loading"

                                            @change="errors.LSPAccountName = null"
                                        />

                                        <app-dropdown-select
                                            class="Time"

                                            v-model="number_form.Time"
                                            :options="time_options"

                                            key-value="value"
                                            key-title="title"

                                            label="Time"

                                            :error="errors.Time"
                                            :disabled="loading"

                                            @change="errors.Time = null"
                                        />

                                        <!--
                                            <app-input-timepicker
                                                v-model="time"

                                                label="Time"

                                                :disabled="!date"
                                            />
                                        -->

                                        <app-input-datepicker
                                            v-model="date"

                                            label="Date to port"
                                        />
                                    </div>
                                    <div class="form-controls">
                                        <app-input
                                            v-model="number_form.SubaccountNumber"

                                            label="Subaccount Number"

                                            :error="errors.SubaccountNumber"
                                            :disabled="loading"

                                            @change="errors.SubaccountNumber = null"
                                        />

                                        <app-input-location-autocomplete
                                            v-if="search_by_locationsvc"

                                            v-model="number_form.Address"

                                            :with-manually="false"

                                            label="Street Address"

                                            :error="errors.Address"
                                            :disabled="loading"

                                            @change-place="onChangePlace"
                                        />
                                        <app-input-google-autocomplete-manually
                                            v-else

                                            v-model="number_form.Address"

                                            :with-manually="false"

                                            label="Street Address"

                                            :error="errors.Address"
                                            :disabled="loading"

                                            @change-place="onChangePlace"
                                        />

                                        <app-textarea
                                            v-model="number_form.Notes"

                                            label="Additional notes for provisioning"

                                            :error="errors.Notes"
                                            :disabled="loading"

                                            @change="errors.Notes = null"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <app-error v-model="errors.save"></app-error>

                    <button class="btn btn-primary btn-post" :disabled="loading || !is_changed" @click="post">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import escapeStringRegexp from 'escape-string-regexp'

import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appRadioButtons from '@/components/app-radio-buttons'
import appDropdownSelect from '@/components/app-dropdown-select'
import appAutocomplete from '@/components/app-autocomplete'
// import appInputTimepicker from '@/components/app-input-timepicker'
import appInputDatepicker from '@/components/app-input-datepicker'

import appInputLocationAutocomplete from '@/components/app-input-location-autocomplete'
import appInputGoogleAutocompleteManually from '@/components/app-input-google-autocomplete-manually'

import SipTrunkValidator from '@/validators/sip-trunk-validator'

import errMessage from '@/helpers/errMessage'

const EMPTY_NUMBER_FORM = {
    Number: '',

    NumberRange: '',
    LSP: '',
    LSPAccountID: '',
    LSPAccountName: '',
    DateTimeNanos: null,
    Time: 'AM',

    SubaccountNumber: '',
    Address: '',
    Notes: '',
}

const allocation_fields = [
    'Number',
]

const port_in_number_fields = [
    'NumberRange',
    'LSP',
    'LSPAccountID',
    'LSPAccountName',
    'DateTimeNanos',
    'Time',
]

const common_fields = [
    'SubaccountNumber',
    'Address',
    'Notes',
]

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appRadioButtons,
        appDropdownSelect,
        appAutocomplete,
        // appInputTimepicker,
        appInputDatepicker,

        appInputLocationAutocomplete,
        appInputGoogleAutocompleteManually,
    },

    data() {
        return {
            number: {
                from: 'allocation',

                options: {
                    allocation: {
                        value: 'allocation',
                        label: 'Allocation',
                    },
                    port_in_number: {
                        value: 'port_in_number',
                        label: 'Port In Number',
                    },
                },
            },

            sip_trunk: null,

            number_form:          JSON.parse(JSON.stringify(EMPTY_NUMBER_FORM)),
            original_number_form: JSON.parse(JSON.stringify(EMPTY_NUMBER_FORM)),

            phone_numbers: [],
            search_query: '',

            loading: false,
            processing: false,

            referrer: null,
            on_sidebar: false,

            errors: {},
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.getSIPTrunk()

            this.getPhoneNumbers()
        },

        getSIPTrunk() {
            this.processing = true

            const params = {
                SPID: this.current_spid,
                UUID: this.sip_trunk_uuid,
            }

            this.$store.dispatch('api_siptrunk/GetSIPTrunkByUUID', params)
                .then(response => {
                    this.sip_trunk = response

                    this.processing = false
                })
                .catch(error => {
                    this.processing = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        getPhoneNumbers() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                PhoneNumberRangeType: 'SIP',
		'SearchOptions.PageSize': -1,
            }

            this.$store.dispatch('api_phonenumber/FindAvailablePhoneNumbersByType', params)
                .then(({ AvailablePhoneNumbers }) => {
                    this.phone_numbers = AvailablePhoneNumbers
                })
                .catch(error => {
                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        filterPhoneNumbers(search_query) {
            this.search_query = search_query

            this.number_form.Number = ''
        },

        selectNumber({ value }) {
            this.number_form.Number = value

            this.$delete(this.errors, 'Number')
        },

        onChangePlace(address) {
            this.number_form.Address = address

            this.$delete(this.errors, 'Address')
        },

        validation() {
            let is_valid = true

            this.errors = {}

            let fields = {
                SubaccountNumber: { rule: 'SubaccountNumber', message: 'Please, enter subaccount number' },
                Address:          { rule: 'Address',          message: 'Please, enter street address'    },
            }

            if (this.is_allocation) {
                fields = {
                    ...fields,

                    Number: { rule: 'PhoneNumber', message: 'Please, select phone number' },
                }
            } else {
                fields = {
                    ...fields,

                    NumberRange: { rule: 'PhoneNumber', message: 'Please, enter phone number' },
                }
            }

            for (const key in fields) {
                if (SipTrunkValidator.isRuleExists(fields[key].rule)) {
                    if (SipTrunkValidator.isInvalid(fields[key].rule, this.number_form[key], fields[key].message)) {
                        this.errors[key] = SipTrunkValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        /*
            post() {
                if (this.validation()) {
                    const delay_in_ms = 1000

                    this.loading = true

                    setTimeout(() => {
                        this.errors = {}
                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    }, delay_in_ms)
                }
            },
        */

        post() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: this.current_spid,
                    UUID: this.sip_trunk_uuid,

                    ...this.sip_trunk,

                    PhoneNumbers: [
                        ...this.sip_trunk.PhoneNumbers,

                        ...(this.is_allocation ? [
                            this.number_form.Number,
                        ] : [
                            this.number_form.NumberRange,
                        ]),
                    ],

                    Addresses: [
                        ...this.sip_trunk.Addresses,

                        this.number_form.Address,
                    ],
                }

                console.log('params', params)

                this.$store.dispatch('api_siptrunk/UpdateSIPTrunk', params)
                    .then(() => {
                        this.errors = {}
                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)
                        this.loading = false
                    })
            }
        },

        reset() {
            this.errors = {}

            this.sip_trunk = null

            if (this.is_allocation) {
                this.search_query = ''
                this.$refs['phone-numbers-autocomplete'].reset()
            }

            this.number_form = JSON.parse(JSON.stringify(EMPTY_NUMBER_FORM))
        },
    },

    computed: {
        ...mapGetters({
            'current_spid': 'current_spid',

            'search_by_google': 'broadband_location_search_by_google',
            'search_by_locationsvc': 'broadband_location_search_by_locationsvc',
        }),

        back_to() {
            return this.referrer && this.referrer.name == 'sip-trunking-sip-trunks'
                ? this.referrer
                : { name: 'sip-trunking-sip-trunks' }
        },

        sip_trunk_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        is_changed() {
            let is_changed = false

            const fields = [
                ...common_fields,
                ...(this.is_allocation ? allocation_fields : port_in_number_fields),
            ]

            for (const key of fields) {
                is_changed = this.number_form[key] != this.original_number_form[key]

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },

        is_allocation() {
            return this.number.from == 'allocation'
        },

        filtered_phone_numbers() {
            let filtered_phone_numbers = []

            if (this.search_query.length > 0) {
                const pattern = new RegExp(escapeStringRegexp(this.search_query), 'gi')

                for (let i = 0, len = this.phone_numbers.length; i < len; i++) {
                    if (pattern.test(this.phone_numbers[i].PhoneNumber)) {
                        filtered_phone_numbers.push(this.phone_numbers[i])
                        pattern.lastIndex = 0
                    }
                }
            } else {
                filtered_phone_numbers = this.phone_numbers
            }

            filtered_phone_numbers = filtered_phone_numbers.map(phone_number => ({
                key:   phone_number.PhoneNumber,
                value: phone_number.PhoneNumber,
            }))

            return filtered_phone_numbers
        },

        date: {
            get() {
                return this.number_form.DateTimeNanos ? (
                    this.number_form.DateTimeNanos instanceof Date
                        ? this.number_form.DateTimeNanos
                        : new Date(this.number_form.DateTimeNanos / 1000000)
                    ) : this.number_form.DateTimeNanos
            },
            set(value) {
                if (!this.date) {
                    value.setHours(0, 0, 0, 0)
                }

                this.number_form.DateTimeNanos = value
            },
        },

        time: {
            get() {
                let [ HH, mm ] = this.date
                    ? this.date.toLocaleTimeString("hi-IN", {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }).split(':')
                    : [null, null]

                if (HH > 23) { HH = "00" }

                return { HH, mm }
            },
            set({ HH, mm }) {
                if (this.date) {
                    let date = new Date(this.date.getTime())
                        date.setHours(HH, mm, 0, 0)

                    this.number_form.DateTimeNanos = date
                }
            },
        },

        time_options() {
            return [
                {
                    value: 'AM',
                    title: 'AM',
                },
                {
                    value: 'PM',
                    title: 'PM',
                },
            ]
        },
    },

    watch: {
        uuid() {
            this.reset()

            if (this.sip_trunk_uuid) {
                this.getSIPTrunk()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-sip-trunk-add-phone-number {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 30px;
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h2 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        .form-container {
            .form-section {
                .form-group {
                    .form-controls {
                        margin-bottom: 30px;

                        .app-textarea {
                            height: 106px;
                        }

                        .app-textarea,
                        .app-input,
                        .app-dropdown-select {
                            margin-bottom: 30px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        .app-dropdown-select {
                            &.Time {
                                max-width: calc(40% - 15px);
                            }
                        }

                        .app-input {
                            &.LSPAccountID,
                            &.app-input-timepicker {
                                max-width: calc(40% - 15px);
                            }

                            &.LSPAccountName,
                            &.app-input-datepicker {
                                max-width: calc(60% - 15px);
                            }
                        }

                        &.port-in-number {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            margin-bottom: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .board {
            h2 {
                &.heading {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        button {
            &.btn-post {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    #page-sip-trunk-add-phone-number {
        .btn-back-to {
            margin-bottom: 16px;
        }

        .board {
            margin-bottom: 16px;
            padding: 16px 8px;

            .form-container {
                .form-section {
                    .form-group {
                        .form-controls {
                            margin-bottom: 24px;

                            .app-textarea,
                            .app-input,
                            .app-dropdown-select {
                                margin-bottom: 24px;
                            }

                            .app-dropdown-select {
                                &.Time {
                                    max-width: 100%;
                                }
                            }

                            .app-input {
                                &.LSPAccountID,
                                &.app-input-timepicker {
                                    max-width: 100%;
                                }

                                &.LSPAccountName,
                                &.app-input-datepicker {
                                    max-width: 100%;
                                }
                            }

                            &.port-in-number {
                                margin-bottom: 24px;
                            }
                        }
                    }
                }
            }
        }

        button {
            &.btn-post {
                max-width: 100%;
            }
        }
    }
}
</style>
